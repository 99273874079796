<template>
  <!-- let's use default until it is finalized and placed in the app -->
  <!--      :width="300"-->
  <!--      :height="300"-->
  <pie-chart :chart-data="pieChartData" :options="pieChartOptions"></pie-chart>
</template>

<script>
import {
  findTaskState,
  findTaskStateColor
} from "@/model/workflow/task/taskModel";

export default {
  name: "ProjectTasksStatusPieChart",
  components: {
    PieChart: () => import("@/utils/VueChartJs/pieChart")
  },
  props: {
    /**
     * Workflow Project
     * @type {{projectId: number, definitionId: number, workflowInstanceId: number, workflowTypeId: number, projectName: String, recordId: number, subject: String, owner: String, projectManagerId: number, templateId: number, templateName: String, workflowInstanceId: number, status: number, statusInfo: String, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}}
     */
    project: undefined
  },
  data() {
    return {
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      columnChartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    /**
     * Compute Data for Pie Chart
     * @return {{datasets: [{backgroundColor: unknown[], data: (number|number)[]}], labels: (*|string)[]}}
     */
    pieChartData() {
      // Assuming you have a project object with userTasks as an array
      const userTasks = this.project?.userTasks || [];

      // Count the occurrences of each task state
      const taskStateCounts = {};
      userTasks.forEach(task => {
        const state = task.status;
        taskStateCounts[state] = (taskStateCounts[state] || 0) + 1;
      });

      const colorMappings = {
        secondary: "lightgrey",
        warning: "orange",
        success: "green"
      };

      // Calculate the total count of tasks
      const totalTasks = userTasks.length;

      // Calculate the proportion of each task state
      const data = Object.values(taskStateCounts).map(count => {
        return totalTasks > 0 ? (count / totalTasks) * 100 : 0;
      });

      const backgroundColor = Object.keys(taskStateCounts).map(state => {
        return findTaskStateColor(Number(state)).name;
      });

      const labels = Object.keys(taskStateCounts).map(state => {
        const task = userTasks.find(task => task.status === parseInt(state));
        return task ? findTaskState(parseInt(state))?.name ?? state : state;
      });

      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: backgroundColor.map(
              value => colorMappings[value] || "indigo"
            ),
            data: data
          }
        ]
      };
    }
  }
};
</script>
